import React, { PropsWithChildren } from "react";
import { Box } from "@mui/material";
import { SxProps, Theme } from "@mui/system";

interface Props extends PropsWithChildren<any> {
  scope?: string;
  align?: string;
  variant?: string;
  sx?: SxProps<Theme>;
}

export default function IDMDivider(props: Props) {
  return (
    <Box
      className={"divider"}
      sx={{
        display: "block",
        width: "240px",
        maxWidth: { xs: "50%", sm: "240px" },
        height: "5px",
        backgroundColor: "primary.main",
        margin: {
          xs: "0.5rem 0 1.5rem 0",
          sm: "1.5rem 0 1.5rem 0",
        },
        ...props.sx,
      }}
    />
  );
}
