import React from "react";
import { Box, Dialog, Slide, Stack } from "@mui/material";
import { Block, BlockType } from "@gk-lab/kirbyreact";
import IDMNavigationLink from "./IDMNavigationLink";
import { defaultPadding } from "../Theme";
import IDMLink from "./IDMLink";
import { SocialLink } from "../types/Block";
import SocialLinkButton from "./SocialLinkButton";
import { TransitionProps } from "@mui/material/transitions";
import { wrapWithRowIDM } from "../utils/sectionUtils";

interface Props {
  open: boolean;
  handleClose(): void;
  handleClick(): void;
  mainItems?: Block[];
  metaItems?: Block[];
  facebook?: string;
  instagram?: string;
  socials?: SocialLink[];
}

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function IDMNavigation(props: Props): React.JSX.Element {
  const { open, handleClose, handleClick, mainItems, metaItems } = props;

  function onClick() {
    setTimeout(() => {
      handleClick();
    }, 250);
  }

  return (
    <Dialog
      fullScreen
      open={open}
      onClose={handleClose}
      TransitionComponent={Transition}
    >
      {mainItems &&
        wrapWithRowIDM(
          <Stack
            direction={"column"}
            className={"navigation-main-items"}
            sx={{
              overflow: "hidden",
            }}
          >
            {mainItems?.map((item, idx) => {
              if (item.type === BlockType.LINK) {
                return (
                  <IDMNavigationLink
                    url={item?.to ?? "/"}
                    onClick={onClick}
                    copy={item.text}
                    key={`${idx.toString()}`}
                  />
                );
              }
              return <Box key={`${idx.toString()}`} />;
            })}
          </Stack>,
          BlockType.NAVIGATION,
        )}
      <Stack
        direction={{ xs: "column", sm: "row" }}
        columnGap={3}
        sx={{
          position: "absolute",
          bottom: "1rem",
          right: defaultPadding,
          left: defaultPadding,
          maxWidth: "1920px",
          margin: "0 auto",
          justifyContent: "space-between",
        }}
      >
        <Stack gap={3} direction={"row"} key={`socials`}>
          {props.socials &&
            props.socials?.map((sl, idx) => {
              return (
                <SocialLinkButton key={`sl-${idx.toString()}`} social={sl} />
              );
            })}
        </Stack>
        <Stack
          sx={{ display: "flex", width: "fit-content" }}
          flexDirection={"row"}
          gap={4}
          className={"meta-navigation"}
        >
          {metaItems?.map((item, idx) => {
            if (item.type === BlockType.LINK) {
              return (
                <IDMLink
                  key={`meta-${idx.toString()}`}
                  data={item}
                  className={"meta-navigation-link"}
                  onClick={handleClick}
                />
              );
            }
            return <Box key={`${idx.toString()}`} />;
          })}
        </Stack>
      </Stack>
    </Dialog>
  );
}
