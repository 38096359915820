import React, { useMemo } from "react";
import { SxProps } from "@mui/system";
import { Theme } from "@mui/material/styles";
import {
  AppBar,
  IconButton,
  Toolbar,
  Box,
  Stack,
  Container,
  Typography,
} from "@mui/material";
import { Menu } from "@mui/icons-material";
import { Link as RouterLink } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import { BlockHeader, KirbyProps, useAppState } from "@gk-lab/kirbyreact";
import { accent, defaultPadding, white } from "../Theme";

interface Props extends KirbyProps {
  asOverlay?: boolean;
  handleOpen?(): void;
  handleClose?(): void;
  data?: BlockHeader;
  /**
   * <Container maxWidth />
   */
  maxWidth: "sm" | "md" | "lg" | "xl" | false;
  /**
   * Added to <Container />
   */
  containerSx?: SxProps<Theme>;
}

function LogoContainer(props: { src: string; alt: string }): React.JSX.Element {
  return <img className={"logo-img"} alt={props.alt} src={props.src} />;
  /*return (
    <Box
      className={"logo-img"}
      aria-label={props.alt}
      sx={{
        // width: "68px",
        height: "90px",
        backgroundImage: `url('${props.src}')`,
        backgroundSize: "contain",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        overflow: "hidden",
      }}
    />
  );*/
}

export default function IDMAppHeader(props: Props) {
  const { header, hidemainnavigation } = useAppState();

  const toolbarStyle: SxProps = {
    maxHeight: "100%",
    height: "90px",
    backgroundColor: props.asOverlay ? accent : "common.white",
  };

  const containerMaxWidth: "sm" | "md" | "lg" | "xl" | false = useMemo(() => {
    switch (props.maxWidth) {
      case "sm":
      case "md":
      case "lg":
      case "xl":
        return props.maxWidth;
      default:
        return false;
    }
  }, [props.maxWidth]);

  return (
    <AppBar
      position="fixed"
      elevation={props.asOverlay ? 0 : 3}
      sx={{ maxWidth: "100vw", left: 0, ...props.sx }}
    >
      <Container
        maxWidth={containerMaxWidth}
        sx={{
          maxHeight: "inherit",
          ...props.containerSx,
        }}
      >
        <Toolbar
          sx={toolbarStyle}
          variant={"regular"}
          // variant={props.asOverlay ? "transparent" : "regular"}
        >
          <Stack
            direction={"row"}
            justifyContent={"space-between"}
            alignItems={"center"}
            sx={{
              width: { xs: "100%" },
              maxHeight: "inherit",
              px: { xs: "1rem", md: "4.5rem" },
            }}
          >
            <Box
              className={"header-logo"}
              component={RouterLink}
              onClick={props.handleClose}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                mr: 4,
                //width: "61px",
                //height: "90px",
              }}
              to={"/"}
              aria-label={header.title}
            >
              <Typography
                variant={"h1"}
                sx={{
                  fontSize: "0 !important",
                  maxHeight: "90px",
                  maxWidth: "calc(100vw - 5rem)",
                  margin: 0,
                  img: { maxHeight: "inherit", width: "100%" },
                }}
              >
                {header.title}
                {!props.asOverlay ? (
                  <LogoContainer src={header.logo} alt={header.title} />
                ) : (
                  <LogoContainer src={header.logoinv} alt={header.title} />
                )}
              </Typography>
            </Box>
            {!props.asOverlay && hidemainnavigation === "false" && (
              <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={props.handleOpen}
                edge="end"
                sx={{
                  width: "3rem",
                  height: "3rem",
                  mr: 0,
                  backgroundColor: accent,
                  color: white,
                  borderRadius: 0,
                  "&:hover,&:focus": {
                    backgroundColor: white,
                    color: accent,
                  },
                }}
              >
                <Menu />
              </IconButton>
            )}
            {props.asOverlay && (
              <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={props.handleClose}
                edge="end"
                sx={{
                  width: "3rem",
                  height: "3rem",
                  mr: 0,
                  backgroundColor: accent,
                  svg: { color: white },
                  borderRadius: 0,
                  "&:hover": {
                    backgroundColor: white,
                    svg: { color: accent },
                  },
                  "&:active, &:focus": {
                    backgroundColor: accent,
                    svg: { color: white },
                  },
                  /*"&:hover,&:focus": {
                    backgroundColor: white,
                    color: accent
                  }*/
                }}
              >
                <CloseIcon />
              </IconButton>
            )}
          </Stack>
        </Toolbar>
        {props.children}
      </Container>
    </AppBar>
  );
}
