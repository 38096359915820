import { createTheme } from "@mui/material";
import { IDMScope } from "./types/Scope";

declare module "@mui/material/Button" {
  interface ButtonPropsVariantOverrides {
    main: true;
    [IDMScope.FOOTER]: true;
    [IDMScope.INFOCARD]: true;
    [IDMScope.HEADER]: true;
    [IDMScope.STAGE]: true;
    [IDMScope.CARD]: true;
  }
}
declare module "@mui/material/Toolbar" {
  interface ToolbarPropsVariantOverrides {
    transparent: true;
  }
}

declare module "@mui/material/Typography" {
  interface TypographyPropsVariantOverrides {
    [IDMScope.FOOTER]: true;
    [IDMScope.INFOCARD]: true;
    [IDMScope.INFOCARD_PRIMARY]: true;
    [IDMScope.INFOCARD_SECONDARY]: true;
    [IDMScope.INFOCARD_ACCENT]: true;
    [IDMScope.HEADER]: true;
    [IDMScope.STAGE]: true;
    [IDMScope.MAIN]: true;
    [IDMScope.NAVIGATION]: true;
  }
}

const font = "Barlow";
const fontFamily = [font, '"Helvetica Neue"', "Arial", "sans-serif"].join(",");

const baseTheme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 700,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
});

export const petrol = "#2b2b2b";

export const footerBackground = petrol;

export const white = "#fff";
export const black = "#000";
export const dark = "#333";

export const primary = "#E40134";
export const primaryDark = "#8d061c";

export const secondary = "#565655";
export const secondaryDark = "#2a2a2a";

export const accent = "#203F39";
export const accentDark = "#0e1c19";

export const lightGrey = "#F4F4F4";

export const defaultPadding = { xs: "1rem", sm: "4.5rem" };

export const slideshowButtonSx = {
  background: accent,
  svg: { color: white },
  ":hover": {
    background: white,
    svg: { color: accent },
  },
  "&:active, &:focus, &.active, &.focus ": {
    background: accent,
    svg: { color: white },
  },
  display: "none",
  [baseTheme.breakpoints.up("md")]: {
    display: "inline-flex",
  },
};

const defaultStyles = {
  "&.bg-grey": {
    backgroundColor: `${lightGrey} !important`,
  },
  "&.bg-darkgrey": {
    backgroundColor: `${secondaryDark} !important`,
  },
  "&.pt-medium": {
    paddingTop: "1rem",
    [baseTheme.breakpoints.up("md")]: {
      paddingTop: "2rem",
    },
  },
  "&.pt-large": {
    paddingTop: "2rem",
    [baseTheme.breakpoints.up("md")]: {
      paddingTop: "4rem",
    },
  },
  "&.pb-medium": {
    paddingBottom: "1rem",
    [baseTheme.breakpoints.up("md")]: {
      paddingBottom: "2rem",
    },
  },
  "&.pb-large": {
    paddingBottom: "2rem",
    [baseTheme.breakpoints.up("md")]: {
      paddingBottom: "4rem",
    },
  },
  "&.px-medium": {
    paddingLeft: "1rem",
    paddingRight: "1rem",
    [baseTheme.breakpoints.up("md")]: {
      paddingLeft: "4rem",
      paddingRight: "4rem",
    },
  },
  "&.px-large": {
    paddingLeft: "1rem",
    paddingRight: "1rem",
    [baseTheme.breakpoints.up("md")]: {
      paddingLeft: "6rem",
      paddingRight: "6rem",
    },
  },
};

export const theme = createTheme({
  breakpoints: baseTheme.breakpoints,
  spacing: 4,
  palette: {
    common: {
      black: "#000",
      white: white,
    },
    primary: {
      main: primary, // color-accent-primary-2
      dark: primaryDark, // color-accent-primary-2-dark
      light: "#d5d5d5", // color-accent-primary-3
    },
    secondary: {
      main: secondary, // color-accent-primary-1
      dark: secondaryDark, // color-accent-primary-2-dark
    },
    error: {
      main: primary,
    },
    warning: {
      main: "#FBC91D", // color-status-warning
      dark: "#AD1035", // color-secundary-1
      light: "rgba(251,201,29,0.15)", // color-status-warning 15 %
    },
    info: {
      main: primary, // color-status-warning-high_prio
      light: "rgba(215,15,55,0.07)", // color-status-warning-high_prio 7 %
    },
    success: {
      main: "#3C992E", // color-status-success
      light: "rgba(10,153,46,0.1)", // color-status-success 10 %
    },
  },
  typography: {
    fontFamily: fontFamily,
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        ...defaultStyles,
      },
    },
    MuiTypography: {
      defaultProps: {
        fontFamily: fontFamily,
        variantMapping: {
          h1: "h1",
          h2: "h2",
          h3: "h3",
          h4: "h4",
          h5: "h5",
          h6: "h6",
          subtitle1: "h3",
          subtitle2: "h3",
          body1: "p",
          body2: "p",
        },
      },
      styleOverrides: {
        root: {
          color: secondaryDark,
          fontSize: "1.625rem",
          lineHeight: 1.2,

          "&.arrow.left": {
            "&::before": {
              content: `url(/svg/arrowtoright.svg)`,
              position: "absolute",
              top: "-4px",
              left: "-146px",
            },
          },
          "&.arrow.right": {
            "&::before": {
              content: `url(/svg/arrowtoleft.svg)`,
              position: "absolute",
              top: "-4px",
              right: "-146px",
            },
          },
          "&.left": {
            textAlign: "left",
          },
          "&.center": {
            textAlign: "center",
          },
          "&.right": {
            textAlign: "right",
          },
          "&.primary": {
            color: secondaryDark,
          },
          "&.secondary": {
            color: white,
          },
          "&.accent": {
            color: white,
          },
          "& p, a": {
            marginBlockStart: "0 !important",
            marginBlockEnd: "1rem !important",
            marginInlineStart: "0 !important",
            marginInlineEnd: "0 !important",
            textAlign: "inherit",
            wordWrap: "break-word",
          },
          p: {
            minHeight: "1rem",
          },
          "& a": {
            color: primary,
            whiteSpace: "nowrap",
          },
          "& a:hover": {
            color: primaryDark,
          },
        },
        body1: {
          // paddingBottom: "1rem",
          "& p, a": {
            lineHeight: 1.2,
          },
          a: {
            color: primary,
            textDecoration: "underline",
            cursor: "pointer",
          },
          "a:hover": {
            color: primaryDark,
            textDecoration: "underline",
          },
          "&:not(.panel)": {
            // maxWidth: "calc(100% - 2rem)",
          },
          // Inline Styles
          "& p, h2, h3, h4, h5, h6": {
            paddingLeft: "0",
            paddingRight: "0",
            wordWrap: "break-word",
          },
          "&.center": {
            alignSelf: "center",
          },
          "&.footer": {
            "> p > a": {
              color: white,
              textDecoration: "none",
              "&:hover,&:focus": {
                color: primary,
              },
            },
          },
          [baseTheme.breakpoints.up("sm")]: {
            lineHeight: 1.5,
            "& p, a": {
              lineHeight: 1.5,
            },
          },
        },
        body2: {
          // paddingBottom: 0,
          "& p, a": {
            // marginBlockEnd: "0",
            wordWrap: "break-word",
          },
          "&.copyright": {
            fontSize: "1rem",
          },
        },
        h1: {
          textTransform: "uppercase",
          fontWeight: "bold",
          fontSize: "2.5rem",
          lineHeight: 0.98,
          marginBottom: "0.5rem",
          position: "relative",
          "&.arrow.left::before": {
            top: "-6px",
          },
          "&.arrow.right::before": {
            top: "-6px",
          },
          [baseTheme.breakpoints.up("sm")]: {
            fontSize: "3rem",
            "&.arrow.left::before": {
              top: "-8px",
            },
            "&.arrow.right::before": {
              top: "-8px",
            },
          },
          [baseTheme.breakpoints.up("md")]: {
            fontSize: "3.5rem",
            "&.arrow.left::before": {
              top: "-12px",
            },
            "&.arrow.right::before": {
              top: "-12px",
            },
          },
          [baseTheme.breakpoints.up("lg")]: {
            fontSize: "4.5rem",
            "&.arrow.left::before": {
              top: "-20px",
            },
            "&.arrow.right::before": {
              top: "-20px",
            },
          },
          [baseTheme.breakpoints.up("xl")]: {
            fontSize: "5rem",
            "&.arrow.left::before": {
              top: "-20px",
            },
            "&.arrow.right::before": {
              top: "-20px",
            },
          },
        },
        h2: {
          textTransform: "uppercase",
          fontWeight: "bold",
          fontSize: "2.25rem",
          lineHeight: 0.98,
          marginBottom: "0.5rem",
          position: "relative",
          "&.arrow.left::before": {
            top: "-6px",
          },
          "&.arrow.right::before": {
            top: "-6px",
          },
          [baseTheme.breakpoints.up("sm")]: {
            fontSize: "2.5rem",
            "&.arrow.left::before": {
              top: "-8px",
            },
            "&.arrow.right::before": {
              top: "-8px",
            },
          },
          [baseTheme.breakpoints.up("md")]: {
            fontSize: "3rem",
            "&.arrow.left::before": {
              top: "-10px",
            },
            "&.arrow.right::before": {
              top: "-10px",
            },
          },
          [baseTheme.breakpoints.up("lg")]: {
            fontSize: "3.5rem",
            "&.arrow.left::before": {
              top: "-12px",
            },
            "&.arrow.right::before": {
              top: "-12px",
            },
          },
          [baseTheme.breakpoints.up("xl")]: {
            fontSize: "4rem",
            "&.arrow.left::before": {
              top: "-16px",
            },
            "&.arrow.right::before": {
              top: "-16px",
            },
          },
        },
        h3: {
          fontSize: "2rem",
          lineHeight: 0.98,
          fontWeight: "bold",
          textTransform: "uppercase",
          marginBottom: "0.5rem",
          position: "relative",
          "&.arrow.left::before": {
            top: "-6px",
          },
          "&.arrow.right::before": {
            top: "-6px",
          },
          [baseTheme.breakpoints.up("sm")]: {
            fontSize: "2.5rem",
          },
          [baseTheme.breakpoints.up("md")]: {
            fontSize: "3rem",
            "&.arrow.left::before": {
              top: "-8px",
            },
            "&.arrow.right::before": {
              top: "-8px",
            },
          },
          [baseTheme.breakpoints.up("lg")]: {
            fontSize: "3.5rem",
            marginBottom: "0.75rem",
            "&.arrow.left::before": {
              top: "-10px",
            },
            "&.arrow.right::before": {
              top: "-10px",
            },
          },
          [baseTheme.breakpoints.up("xl")]: {
            "&.arrow.left::before": {
              top: "-12px",
            },
            "&.arrow.right::before": {
              top: "-12px",
            },
          },
        },
        h4: {
          fontSize: "2rem",
          fontWeight: "bold",
          marginBottom: "0.25rem",
          // textTransform: "uppercase",
          position: "relative",
          "&.arrow.left::before": {
            top: "-6px",
          },
          "&.arrow.right::before": {
            top: "-6px",
          },
          [baseTheme.breakpoints.up("sm")]: {
            fontSize: "2.25rem",
          },
          [baseTheme.breakpoints.up("md")]: {
            fontSize: "2.5rem",
            "&.arrow.left::before": {
              top: "-7px",
            },
            "&.arrow.right::before": {
              top: "-7px",
            },
          },
          [baseTheme.breakpoints.up("lg")]: {
            fontSize: "2.75rem",
          },
          [baseTheme.breakpoints.up("xl")]: {
            fontSize: "3rem",
            "&.arrow.left::before": {
              top: "-8px",
            },
            "&.arrow.right::before": {
              top: "-8px",
            },
          },
        },
        h5: {
          fontSize: "1.5rem",
          fontWeight: "bold",
          color: accent,
          margin: "0",
          lineHeight: "1.8",
          marginBottom: "0",
          position: "relative",
          "&.arrow.left::before": {
            top: "0px",
          },
          "&.arrow.right::before": {
            top: "0px",
          },
          [baseTheme.breakpoints.up("lg")]: {
            fontSize: "1.5rem",
          },
        },
        h6: {
          fontSize: "1rem",
          transform: "none",
          fontWeight: "lighter",
          marginBottom: "0.25rem",
          marginTop: "0.25rem",
          [baseTheme.breakpoints.up("lg")]: {
            fontSize: "1.25rem",
          },
        },
        subtitle1: {
          fontSize: "2.0rem",
          lineHeight: "1",
          fontWeight: "bold",
          textTransform: "uppercase",
          marginTop: "0",
          marginBottom: "1rem",
          [baseTheme.breakpoints.up("lg")]: {
            fontSize: "3rem",
          },
        },
        subtitle2: {
          color: white,
          fontSize: "2.0rem",
          lineHeight: "1",
          fontWeight: "bold",
          textTransform: "uppercase",
          marginTop: "0",
          marginBottom: "1rem",
          [baseTheme.breakpoints.up("lg")]: {
            fontSize: "3rem",
          },
        },
      },
      variants: [
        {
          props: { variant: IDMScope.FOOTER },
          style: {
            color: white,
            paddingBottom: "0",
            textAlign: "center",
            "& a, p": {
              color: white,
              textAlign: "center",
              marginBlockEnd: "0 !important",
              textDecoration: "none",
            },
            "& a:hover": {
              // fontWeight: "bold",
              color: primary,
            },
            [baseTheme.breakpoints.up("lg")]: {},
          },
        },
        {
          props: { variant: IDMScope.INFOCARD_PRIMARY },
          style: {
            color: secondaryDark,
            paddingBottom: "1rem",
            "& p, a": {
              lineHeight: 1.2,
            },
            "& h2,h3,h4,h5,h6": {
              marginTop: 0,
              marginBottom: "1.5rem",
            },
            [baseTheme.breakpoints.up("md")]: {
              "& p, a": {
                lineHeight: 1.5,
              },
            },
          },
        },
        {
          props: { variant: IDMScope.INFOCARD_SECONDARY },
          style: {
            color: secondaryDark,
            paddingBottom: "1rem",
            "& p, a": {
              lineHeight: 1.2,
            },
            "& h2,h3,h4,h5,h6": {
              marginTop: 0,
              marginBottom: "1.5rem",
            },
            h2: {
              position: "relative",
              fontSize: "2.25rem",
              lineHeight: 0.98,
              fontWeight: "bold",
              textTransform: "uppercase",
              "&::before": {
                position: "absolute",
                width: "129px",
                height: "16px",
                content: `url(svg/arrowtoright.svg)`,
                left: "-140px",
                top: "-4px",
              },
            },
            [baseTheme.breakpoints.up("md")]: {
              "& p, a": {
                lineHeight: 1.5,
              },
            },
          },
        },
        {
          props: { variant: IDMScope.INFOCARD_ACCENT },
          style: {
            color: white,
            paddingBottom: "1rem",
            "& p, a": {
              lineHeight: 1.2,
            },
            "& h2,h3,h4,h5,h6": {
              marginTop: 0,
              marginBottom: "1.5rem",
            },
            [baseTheme.breakpoints.up("lg")]: {
              "& p, a": {
                lineHeight: 1.5,
              },
            },
          },
        },
      ],
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 0,
          borderWidth: 0,
          marginBottom: "1.5rem",
          width: "fit-content",
          textTransform: "uppercase",
          "& :hover, &:focus": {
            backgroundColor: primaryDark,
          },
          "& svg": {
            background: "transparent !important",
          },
          fontSize: "1.5rem",
          [baseTheme.breakpoints.up("md")]: {
            fontSize: "1.625rem",
          },
        },
      },
      variants: [
        {
          props: { variant: "contained" },
          style: {
            color: white,
            alignSelf: "center",
            textAlign: "center",
            lineHeight: 1.1,
            padding: "1rem",
            backgroundColor: `${primary} !important`,
            "&.active": {
              color: white,
              backgroundColor: primary,
            },
            "&:hover,&:focus": {
              color: white,
              backgroundColor: `${primaryDark} !important`,
            },
          },
        },
      ],
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          backgroundColor: accent,
          borderRadius: 0,
          color: white,
          "&.active": {
            color: white,
            backgroundColor: primary,
          },
          "&:hover,&:focus": {
            color: white,
            backgroundColor: primaryDark,
          },
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        root: {
          // backgroundColor: accent,
        },
        paperFullScreen: {
          backgroundColor: accent,
        },
      },
    },
    MuiContainer: {
      styleOverrides: {
        root: {
          paddingLeft: "0 !important",
          paddingRight: "0 !important",
          ".imagelist": {
            ".container-elements": {
              marginTop: "-1px",
              marginLeft: "-1px",
            },
            ".image": {
              outline: "1px solid #707070",
              marginTop: "1px",
              marginLeft: "1px",
            },
          },
          ".infoimage": {
            "h2,h3,h4": {
              position: "relative",
              marginTop: "1rem",
            },
          },
          ".footer-meta-container": {
            backgroundColor: white,
            padding: "1rem",
            flexDirection: "column",
            justifyContent: "column",
            [baseTheme.breakpoints.up("sm")]: {
              padding: "1rem 6rem 2rem 6rem",
              flexDirection: "row",
              justifyContent: "space-between",
            },
          },
        },
        maxWidthXl: {
          paddingLeft: "0",
          paddingRight: "0",
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: "transparent",
        },
      },
    },
    MuiFormGroup: {
      styleOverrides: {
        root: {
          "label > span": {
            fontSize: "1rem",
          },
        },
      },
    },
    MuiToolbar: {
      styleOverrides: {
        root: {
          minHeight: "4rem",
          backgroundColor: white,
          color: dark,
          paddingLeft: "0 !important",
          paddingRight: "0 !important",
        },
      },
      variants: [
        {
          props: { variant: "transparent" },
          style: {
            background: "transparent",
          },
        },
      ],
    },
    MuiLink: {
      defaultProps: {
        fontFamily: fontFamily,
      },
      styleOverrides: {
        root: {
          paddingLeft: 0,
          paddingRight: 0,
          lineHeight: 1.2,
          "&.primary": {
            textDecorationColor: secondary,
            "&:hover,&:focus": {
              color: primary,
              textDecorationColor: primary,
            },
          },
          "&.secondary, &.meta-navigation-link": {
            color: secondary,
            textDecorationColor: secondary,
            "&:hover,&:focus": {
              color: primary,
              textDecorationColor: primary,
            },
          },
          "&.footer": {
            textDecoration: "none",
            lineHeight: 1.5,
            "&:hover,&:focus": {},
          },
          "&.footer-meta-navigation": {
            fontSize: "1rem",
            "&:not(:last-child):after": {
              content: '"|"',
              margin: "0 0 0 0.5rem",
              textDecoration: "none",
            },
            "&:hover,&:focus": {
              "&::after": {
                color: secondary,
              },
            },
          },
          "&.meta-navigation-link": {
            color: white,
            textDecoration: "none",
            "&:not(:last-child):after": {
              content: '"|"',
              margin: "0 0.5rem",
              textDecoration: "none",
            },
            "&:hover,&:focus": {
              "&::after": {
                color: white,
              },
            },
          },
          "&.accent": {
            color: white,
            textDecorationColor: white,
            "&:hover,&:focus": {
              color: primary,
              textDecorationColor: primary,
            },
          },
          "&.footer.secondary": {
            color: white,
            textDecorationColor: white,
            "&:hover,&:focus": {
              color: primary,
              textDecorationColor: primary,
            },
          },
          "&.main-navigation": {
            color: white,
            textTransform: "uppercase",
            textDecoration: "none",
            fontSize: "3rem",
            fontWeight: "bold",
            marginBottom: 0,
            lineHeight: 1,
            paddingTop: 0,
            [baseTheme.breakpoints.up("sm")]: {
              fontSize: "6rem",
            },
            [baseTheme.breakpoints.up("md")]: {
              fontSize: "6rem",
            },
            "&:hover,&:focus": {
              color: primary,
              textDecoration: "none",
            },
            "&.active": {
              color: primary,
              textDecoration: "none",
            },
          },
          "&.center": {
            margin: "0 auto",
          },
          [baseTheme.breakpoints.up("lg")]: {
            lineHeight: 1.5,
          },
        },
      },
      variants: [
        {
          props: { variant: IDMScope.MAIN },
          style: {
            color: white,
            textTransform: "uppercase",
            // background: "transparent",
            textDecoration: "none",
            textAlign: "right",
            fontWeight: "bold",
            fontSize: "3rem",
            marginBottom: 0,
            lineHeight: 1,
            paddingTop: 0,
          },
        },
        {
          props: { variant: IDMScope.FOOTER },
          style: {
            color: white,
            textDecorationColor: white,
            textAlign: "center",
            marginBlockEnd: "0 !important",
            ":hover": {
              // fontWeight: "bold",
              color: primary,
              // textDecorationColor: white,
            },
            [baseTheme.breakpoints.up("lg")]: {
              // fontSize: "1rem",
            },
          },
        },
        {
          props: { variant: IDMScope.NAVIGATION },
          style: {
            color: white,
            textDecoration: "none",
            textTransform: "uppercase",
            textAlign: "center",
            fontSize: "1.25rem",
            ":hover": {
              color: primary,
            },
            [baseTheme.breakpoints.up("sm")]: {
              fontSize: "2rem",
            },
          },
        },
      ],
    },
    MuiDivider: {
      styleOverrides: {
        root: {},
        vertical: {
          borderColor: "rgba(255,255,255,0.3)",
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius: 0,
          boxShadow: "none",
        },
      },
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          padding: "1rem 1rem",
          ".card-title": {
            // textAlign: "center",
            color: petrol,
            fontSize: "1.25rem",
            fontWeight: "bold",
            marginBottom: "0.5rem",
            lineHeight: 1,
            textTransform: "upperCase",
            padding: 0,
            //maxHeight: "3.5rem",
            wordWrap: "break-word",
            textAlign: "left",
            [baseTheme.breakpoints.up("md")]: {
              fontSize: "1.75rem",
            },
          },
          ".card-subtitle": {
            fontSize: "1rem",
            marginBottom: "0.25rem",
            lineHeight: 1,
            padding: 0,
            fontWeight: "bold",
            textAlign: "left",
            color: primary,
            [baseTheme.breakpoints.up("md")]: {
              fontSize: "1.5rem",
            },
          },
          ".card-copy": {
            // textAlign: "center",
            fontSize: "1.25rem",
            margin: "0.5rem 0 0.25rem 0",
            padding: 0,
            "> p": {
              margin: 0,
            },
          },
        },
      },
    },
    MuiCardActions: {
      styleOverrides: {
        root: {
          height: "100%",
          position: "absolute",
          top: 0,
          bottom: 0,
          right: "1rem",
          display: "flex",
          justifyContent: "center",
        },
      },
    },
  },
});
