import React, { useCallback, useMemo, useState } from "react";
import {
  getClassName,
  KirbyCarousel,
  KirbyProps,
  parseCss,
} from "@gk-lab/kirbyreact";
import { Box, Stack, Typography } from "@mui/material";
import { BlockCasesOverview } from "../types/Block";
import CaseView from "./CaseView";
import { defaultPadding, lightGrey, slideshowButtonSx } from "../Theme";
import { useLocation } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

interface Props extends KirbyProps {
  data: BlockCasesOverview;
}

export default function CasesOverview(props: Props) {
  const location = useLocation();
  const [activeTags, setActiveTags] = useState<string[]>([]);

  const theme = useTheme();
  const isSm = useMediaQuery(theme.breakpoints.up("sm"));
  const isMd = useMediaQuery(theme.breakpoints.up("md"));
  const isLg = useMediaQuery(theme.breakpoints.up("lg"));
  const isXl = useMediaQuery(theme.breakpoints.up("xl"));

  const { data, sx } = props;
  const { id, filter, cases, tags, variant, css } = data;

  const localTags: string[] | undefined = useMemo(() => {
    const myTags: string[] = [];
    if (filter && cases && cases.length > 0) {
      cases.forEach((bc) => {
        if (bc.tags) {
          bc.tags.split(",").forEach((tag) => {
            if (myTags.indexOf(tag.trim()) === -1) {
              // Nur Tags hinzufügen, die auch vorhanden sind
              if (tags) {
                if (tags.indexOf(tag.trim()) !== -1) {
                  // Nur hinzufügen, wenn Tag auch in der allgemeinen Tag-Liste der Komponente ist
                  myTags.push(tag.trim());
                }
              } else {
                myTags.push(tag.trim());
              }
            }
          });
        }
      });
      return myTags;
    }
    return undefined;
  }, [cases, filter, tags]);

  function onTagClick(tagValue: string) {
    const tagIdx = activeTags.indexOf(tagValue.toLowerCase());
    if (tagIdx !== -1) {
      const tempTags = activeTags.filter((t) => t !== tagValue.toLowerCase());
      setActiveTags(tempTags);
    } else {
      setActiveTags([...activeTags, tagValue.trim().toLowerCase()]);
    }
  }

  function getTag(tagValue: string): React.JSX.Element {
    const isActive: boolean = activeTags.includes(tagValue.toLowerCase());
    return (
      <Box
        component={"li"}
        sx={{
          px: 1,
          cursor: "pointer",
        }}
        key={tagValue}
        onClick={() => onTagClick(tagValue.toLowerCase())}
      >
        <Typography
          component="span"
          gutterBottom={false}
          sx={{
            color: isActive ? "primary.main" : "secondary.main",
            fontSize: { xs: "1.25rem", md: "1.625rem" },
          }}
        >{`#${tagValue.toLowerCase().trim()}`}</Typography>
      </Box>
    );
  }

  const activeCards = useMemo(() => {
    if (cases && cases.length > 0 && activeTags && activeTags.length > 0) {
      const filteredCases = cases.filter((bc) => {
        if (bc.tags) {
          const cardTags = bc.tags.split(",");
          let found: boolean = false;
          cardTags.forEach((cardTag) => {
            if (activeTags.includes(cardTag.toLowerCase().trim())) {
              found = true;
            }
          });
          return found;
        }
        return false;
      });
      return filteredCases;
    }
    return cases
      ? cases.filter((c) => {
          return location.pathname.substring(1) !== c.url;
        })
      : [];
  }, [cases, activeTags, location.pathname]);

  function _getCaseGroup(startIdx: number, count: number): React.JSX.Element[] {
    const end = startIdx + count;
    const result: React.JSX.Element[] = [];
    for (
      let i = startIdx;
      i < (end < activeCards.length ? end : activeCards.length);
      i++
    ) {
      const c = activeCards[i];
      if (c) {
        result.push(<CaseView key={`case-${i}`} data={c} sx={{ mb: 16 }} />);
      }
    }
    return result;
  }

  const getCaseGroup = useCallback(_getCaseGroup, [activeCards]);

  const slides: React.JSX.Element[] = useMemo(() => {
    const result: React.JSX.Element[] = [];
    let stackInfo = {
      count: 0,
      caseCount: 1,
    };
    if (activeCards) {
      if (isXl)
        stackInfo = { count: Math.ceil(activeCards?.length / 4), caseCount: 4 };
      else if (isLg)
        stackInfo = { count: Math.ceil(activeCards?.length / 3), caseCount: 3 };
      else if (isMd)
        stackInfo = { count: Math.ceil(activeCards?.length / 2), caseCount: 2 };
      else if (isSm)
        stackInfo = { count: Math.ceil(activeCards?.length / 2), caseCount: 2 };
      else stackInfo = { count: activeCards?.length, caseCount: 1 };
    }

    if (stackInfo.count > 0) {
      for (let i = 0; i < stackInfo.count; i++) {
        const start = i * stackInfo.caseCount;
        const cases = getCaseGroup(start, stackInfo.caseCount);
        result.push(
          <Stack
            flexDirection={"row"}
            gap={8}
            px={{ xs: "0", sm: "4rem" }}
            width={"100%"}
            className={"cases-container"}
          >
            {cases}
          </Stack>,
        );
      }
    }
    return result;
  }, [isSm, isMd, isLg, isXl, activeCards, getCaseGroup]);

  return (
    <Box
      id={id?.toLowerCase()}
      sx={{
        backgroundColor: lightGrey,
        ...(css && parseCss(css)),
      }}
      className={getClassName(props.data, "cases-group")}
    >
      {/*<JSONViewer name={"Cases Overview"} data={props.data} />*/}
      {localTags && (
        <Box
          sx={{
            textAlign: "center",
            pt: 8,
          }}
        >
          <Typography
            component="h4"
            sx={{
              fontWeight: "bold",
              textTransform: "uppercase",
            }}
          >
            Filter
          </Typography>
          <Stack
            component={"ul"}
            direction={"row"}
            flexWrap={"wrap"}
            justifyContent={"center"}
            sx={{
              listStyle: "none",
              px: { xs: "1rem", md: "4rem" },
              py: 0,
              mb: 8,
            }}
          >
            {localTags?.map((tag) => getTag(tag))}
          </Stack>
        </Box>
      )}
      {variant === "slider" ? (
        <Box sx={{ pt: 8, ...sx }} className={"slider"}>
          <KirbyCarousel
            buttonSx={slideshowButtonSx}
            indicatorPos={"right"}
            navigationPos={"spread"}
            hideNavigation={false}
            indicatorContainerSx={{ right: "7rem" }}
            //duration={props.data.duration}
          >
            {slides}
          </KirbyCarousel>
        </Box>
      ) : (
        <Stack
          flexDirection={"row"}
          flexWrap={"wrap"}
          rowGap={12}
          columnGap={4}
          width={"100%"}
          sx={{ px: defaultPadding, pb: 16, ...sx }}
          className={"list"}
        >
          {activeCards.map((bc, idx) => {
            return (
              <CaseView key={`case-${bc.title}-${idx.toString()}`} data={bc} />
            );
          })}
        </Stack>
      )}
    </Box>
  );
}
