import React from "react";
import { CardMedia, IconButton, Stack, Typography } from "@mui/material";
import { BlockType, KirbyCard, KirbyProps } from "@gk-lab/kirbyreact";
import { BlockCase } from "../types/Block";
import { ArrowForwardIos } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";

interface Props extends KirbyProps {
  data: BlockCase;
}

export default function CaseView(props: Props) {
  const navigate = useNavigate();
  const { data } = props;
  const { title, subtitle, image, url } = data;

  return (
    <KirbyCard
      sx={{
        width: {
          xs: "100%",
          sm: "calc(50% - 0.75rem)",
          md: "calc(50% - 0.75rem)",
          lg: "calc(33% - 0.75rem)",
          xl: "calc(25% - 0.75rem)",
        },
        display: "flex",
        height: "auto",
        minHeight: "380px",
        backgroundColor: "common.white",
        flexDirection: {
          xs: "column",
        },
        cursor: "pointer",
        ...props.sx,
      }}
      cardProps={{
        onClick: () => {
          navigate(`/${url}`);
        },
      }}
      data={{ type: BlockType.CARD, heading: title, subtitle }}
      media={
        image && image.url ? (
          <CardMedia
            sx={{
              width: "100%",
              aspectRatio: "4 / 3",
              display: "block",
            }}
            // ref={ref}
            image={image.url}
            title={image.name}
          />
        ) : undefined
      }
      contentSx={{ position: "relative" }}
      cta={
        url ? (
          <IconButton size={"large"} sx={{ alignSelf: "flex-end" }}>
            <ArrowForwardIos fontSize={"medium"} to={`/${url}`} />
          </IconButton>
        ) : null
      }
    >
      <Stack
        sx={{
          flexGrow: 1,
          pr: 20,
        }}
        direction={"column"}
      >
        {title && (
          <Typography className={"card-title"} gutterBottom component="h4">
            {title}
          </Typography>
        )}
        {subtitle && (
          <Typography
            className={"card-subtitle"}
            gutterBottom
            component="h4"
            dangerouslySetInnerHTML={{ __html: subtitle }}
          />
        )}
      </Stack>
    </KirbyCard>
  );
}
