import React from "react";
import { Box, Container, Stack, Typography } from "@mui/material";
import { Block, useAppState } from "@gk-lab/kirbyreact";
// import Leaf from "../svg/Leaf";
import { IDMScope } from "../types/Scope";
import { footerBackground } from "../Theme";
import SocialLinkButton from "./SocialLinkButton";
import { SocialLink } from "../types/Block";
import { SxProps, Theme } from "@mui/system";

interface Props {
  getBlockElement(
    b: Block,
    idx: number,
    scope?: string,
  ): React.JSX.Element | null;
  sx?: SxProps<Theme>;
}

export default function IDMFooter(props: Props) {
  const { footer, sociallinks } = useAppState();

  const {
    top,
    leftheading,
    left,
    centerheading,
    center,
    rightheading,
    right,
    meta,
    copyright,
  } = footer;

  return (
    <Box
      component={"footer"}
      sx={{
        width: "100%",
        zIndex: 1,
        ...props.sx,
      }}
    >
      <Container
        maxWidth={false}
        sx={{
          maxWidth: "1920px",
          backgroundColor: footerBackground,
        }}
      >
        <Stack dir={"column"} width={"100%"} pt={4}>
          {/*<Box display={"flex"} justifyContent={"center"} mt={-14}>
            <Leaf />
          </Box>*/}
          {top?.map((b: Block, idx: number) => {
            return props.getBlockElement(b, idx, IDMScope.FOOTER);
          })}
        </Stack>
        <Stack
          direction={{ xs: "column", md: "row" }}
          justifyContent={"space-between"}
          rowGap={{ xs: 4, lg: 0 }}
          sx={{
            width: "100%",
            padding: { xs: "1rem" },
            mb: 12,
          }}
        >
          <Stack direction={"column"} width={{ xs: "100%", lg: `${100 / 3}%` }}>
            <Typography
              component={"h5"}
              variant={IDMScope.FOOTER}
              sx={{
                color: (theme) => theme.palette.common.white,
                wordWrap: "break-word",
              }}
            >
              {leftheading}
            </Typography>
            {left?.map((b: Block, idx: number) => {
              return props.getBlockElement(b, idx, IDMScope.FOOTER);
            })}
          </Stack>
          <Stack direction={"column"} width={{ xs: "100%", lg: `${100 / 3}%` }}>
            <Typography
              component={"h5"}
              variant={IDMScope.FOOTER}
              sx={{
                color: (theme) => theme.palette.common.white,
                wordWrap: "break-word",
              }}
              style={{ wordWrap: "break-word" }}
            >
              {centerheading}
            </Typography>
            {center?.map((b: Block, idx: number) => {
              return props.getBlockElement(b, idx, IDMScope.FOOTER);
            })}
          </Stack>
          <Stack direction={"column"} width={{ xs: "100%", lg: `${100 / 3}%` }}>
            <Typography
              component={"h5"}
              variant={IDMScope.FOOTER}
              sx={{
                color: (theme) => theme.palette.common.white,
                wordWrap: "break-word",
              }}
              style={{ wordWrap: "break-word" }}
            >
              {rightheading}
            </Typography>
            {right?.map((b: Block, idx: number) => {
              return props.getBlockElement(b, idx, IDMScope.FOOTER);
            })}
          </Stack>
        </Stack>
        {sociallinks && (
          <Stack
            gap={3}
            direction={"row"}
            key={`socials`}
            sx={{
              width: "100%",
              justifyContent: "center",
              py: 2,
            }}
          >
            {sociallinks?.map((sl: SocialLink, idx: number) => {
              return (
                <SocialLinkButton key={`sl-${idx.toString()}`} social={sl} />
              );
            })}
          </Stack>
        )}
      </Container>
      <Box
        className={"footer-bottom"}
        sx={{
          backgroundColor: (theme) => theme.palette.common.white,
          width: "100%",
          zIndex: 2,
          minHeight: "3rem",
        }}
        mt={-4}
      >
        <Container maxWidth={false} sx={{ maxWidth: "1920px" }}>
          <Stack
            className={"footer-meta-container"}
            sx={{
              width: "100%",
            }}
          >
            <Box
              sx={{ display: "flex", width: "100%", justifyContent: "center" }}
            >
              <Typography
                component={"p"}
                variant={"body2"}
                sx={{
                  wordWrap: "break-word",
                  px: 6,
                }}
                style={{ wordWrap: "break-word" }}
                className={"copyright"}
              >
                {copyright}
              </Typography>
            </Box>
            <Stack
              flexDirection={"row"}
              columnGap={4}
              className={"footer-meta"}
            >
              {meta?.map((b: Block, idx: number) => {
                return props.getBlockElement(b, idx, "footer-meta-navigation");
              })}
            </Stack>
          </Stack>
        </Container>
      </Box>
    </Box>
  );
}
