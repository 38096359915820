import React from "react";
import { BlockType, KirbyButton, KirbyProps } from "@gk-lab/kirbyreact";
import { BlockCookieReset } from "../types/Block";
import { defaultPadding } from "../Theme";
import { Box } from "@mui/material";

interface Props extends KirbyProps {
  data: BlockCookieReset;
  scope?: string;
  className?: string;
  onClick?(): void;
}

export default function CookieReset(props: Props): React.JSX.Element {
  function onClick(e: any) {
    if (e) {
      e.preventDefault();
    }
    // @ts-ignore
    window.ResetManager();
  }

  return (
    <Box
      sx={{
        px: defaultPadding,
      }}
    >
      <KirbyButton
        data={{
          ...props.data,
          type: BlockType.BUTTON,
        }}
        onClick={onClick}
      />
    </Box>
  );
}
