import React from "react";
import {
  Block,
  BlockPageContainer,
  JSONViewer,
  KirbyProps,
  parseCss,
  useSection,
} from "@gk-lab/kirbyreact";
import { IDMBlock } from "../types/Block";
import { Box } from "@mui/material";

interface Props extends KirbyProps {
  data: BlockPageContainer;
  getBlocks(
    b: Block | IDMBlock,
    idx: number,
    scope?: string,
  ): React.JSX.Element | null;
}

export default function PageContainer(props: Props): React.JSX.Element {
  const { data, getBlocks } = props;
  const { page, css } = data;
  const sectionData = useSection(page);
  console.log("default - PageContainer: ", page, sectionData.blocks);

  return sectionData && sectionData.blocks ? (
    <Box
      className={`page-container ${page}`}
      sx={{ ...(css && parseCss(css)) }}
    >
      {sectionData.blocks.map((b, idx) => getBlocks(b, idx))}
    </Box>
  ) : (
    <JSONViewer data={props.data} name={"Pagecontainer"} />
  );
}
