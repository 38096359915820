import React, { ReactNode } from "react";
import { Box, Stack } from "@mui/material";
import { SxProps } from "@mui/system";
import {
  Align,
  BlockType,
  FlexAlign,
  KirbyButton,
  KirbyImage,
  KirbyProps,
} from "@gk-lab/kirbyreact";
import { SectionCase } from "../types/Section";
import { getBlockElement, wrapWithRowIDM } from "../utils/sectionUtils";
import { white } from "../Theme";
import IDMHeadline from "../components/IDMHeadline";
import { IDMScope } from "../types/Scope";
import CasesOverview from "../components/CasesOverview";
import { IDMBlockType } from "../types/Block";
import IDMFooter from "../components/IDMFooter";

interface Props extends KirbyProps {
  data: SectionCase;
  inline?: boolean;
  parentUrl?: string;
}

function getSection(
  id: string | undefined,
  children: ReactNode,
  sx?: SxProps<any> | undefined,
  inline?: boolean,
) {
  return (
    <Box
      component={"section"}
      display={"flex"}
      flexDirection={"column"}
      sx={{
        padding: "0",
        minHeight: inline ? "unset" : "calc( 100vh - 4rem)",
        ...sx,
      }}
      id={id}
    >
      {children}
    </Box>
  );
}

export default function CasePage(props: Props) {
  const { data } = props;
  const { blocks, url, header, title, cases } = data;

  return getSection(
    data?.url ?? undefined,
    <>
      {header &&
        wrapWithRowIDM(
          <Box sx={{ backgroundColor: white, pb: 16 }}>
            <KirbyImage
              data={{ type: BlockType.IMAGE, image: { xs: header } }}
              sx={{
                display: "flex",
                alignItems: "center",
                minHeight: { xs: "180px", md: "240px" },
                px: 16,
              }}
            >
              <IDMHeadline
                data={{
                  type: BlockType.HEADLINE,
                  text: title,
                  arrow: true,
                  align: Align.left,
                  level: "h3",
                  variant: "secondary",
                }}
              />
            </KirbyImage>
          </Box>,
          BlockType.PAGE,
          0,
        )}
      {blocks &&
        blocks.map((block, idx) => {
          const component = getBlockElement(block, idx, IDMScope.MAIN);
          return wrapWithRowIDM(component, BlockType.PAGE, idx + 1);
        })}
      {wrapWithRowIDM(
        <Stack direction={"column"}>
          <IDMHeadline
            data={{
              type: BlockType.HEADLINE,
              text: "Weitere Cases",
              level: "h4",
              divider: true,
              uistyle: {
                pt: "large",
                pb: "none",
                px: "medium",
                backgroundcolor: "grey",
              },
            }}
          />
          <CasesOverview
            data={{
              type: IDMBlockType.CASESOVERVIEW,
              cases,
              variant: "slider",
            }}
            sx={{ px: { xs: "1rem", md: "4.5rem" }, py: 8 }}
          />
        </Stack>,
        BlockType.PAGE,
        20,
      )}
      {wrapWithRowIDM(
        <KirbyButton
          data={{
            type: BlockType.BUTTON,
            to: url.substring(0, url.lastIndexOf("/")),
            text: "Zurück zur Übersicht",
            align: FlexAlign.center,
          }}
          sx={{
            mt: 16,
            mb: 24,
          }}
        />,
        BlockType.PAGE,
        30,
      )}
      {data.showfooter && <IDMFooter getBlockElement={getBlockElement} />}
    </>,
    props.sx,
    props.inline,
  );
}
