import React, { PropsWithChildren, useMemo } from "react";
import {
  Align,
  getClassName,
  KirbyHeading,
  parseCss,
} from "@gk-lab/kirbyreact";
import { Stack } from "@mui/material";
import IDMDivider from "./IDMDivider";
import { BlockIdmHeadline } from "../types/Block";

interface Props extends PropsWithChildren<any> {
  data: BlockIdmHeadline;
  scope?: string;
}
export default function IDMHeadline(props: Props) {
  const { data } = props;
  const { css, align, arrow, variant } = data;

  const className = useMemo(() => {
    let cl = [];
    if (variant) cl.push(variant);
    if (align) cl.push(align);
    if (arrow) cl.push("arrow");
    return cl.join(" ");
  }, [variant, align, arrow]);

  const flexAlign = useMemo(() => {
    if (align === Align.left) return "flex-start";
    if (align === Align.right) return "flex-end";
    return "center";
  }, [align]);

  function dup(cssValue: string) {
    return css ? css[cssValue] : "";
  }

  const containerCss = {
    backgroundcolor: dup("backgroundcolor"),
    marginbottom: dup("marginbottom"),
    paddingbottom: dup("paddingbottom"),
    paddingy: dup("paddingy"),
  };

  return (
    <Stack
      direction={"column"}
      sx={{
        height: "fit-content",
        width: "100%",
        alignItems: flexAlign,
        ...(css && parseCss(containerCss)),
        ...props.sx,
      }}
      id={props.id}
      className={getClassName(props.data, "headline-container")}
    >
      <KirbyHeading
        data={{
          ...props.data,
          css: {
            ...css,
            backgroundcolor: "",
            marginbottom: "",
            paddingbottom: "",
            paddingy: "",
          },
          uistyle: {},
        }}
        sx={{
          ...props.sx,
        }}
        className={className}
        id={props.data?.id}
      />
      {props.data.divider && (
        <IDMDivider align={props.data?.align} variant={props.data?.variant} />
      )}
    </Stack>
  );
}
