import {SectionCase, SectionCases, SectionDefault} from "../types/Section";
import {
  Block,
  BlockType,
  getKirbyBlockElement,
  IKirbySection,
  KirbyBlockGroup,
  KirbySlide,
  KirbySlideshow,
} from "@gk-lab/kirbyreact";
import React, {ReactNode} from "react";
import {Container, Grid, Stack} from "@mui/material";
import IDMHeadline from "../components/IDMHeadline";
import IDMLink from "../components/IDMLink";
import IDMDivider from "../components/IDMDivider";
import {IDMBlock, IDMBlockType} from "../types/Block";
import IDMText from "../components/IDMText";
import CookieReset from "../components/CookieReset";
import CasesOverview from "../components/CasesOverview";
import {slideshowButtonSx} from "../Theme";
import CasesPage from "../pages/CasesPage";
import CasePage from "../pages/CasePage";
import DefaultPage from "../pages/DefaultPage";
import PageContainer from "../components/PageContainer";
import KirbyForm from "../components/KirbyForm/KirbyForm";

export type IDMSSection =
  | SectionCase
  | SectionCases
  | SectionDefault
  | IKirbySection;

export function wrapWithRowIDM (element:ReactNode | null, type?:BlockType | "", idx?:number):React.JSX.Element | ReactNode | null {
  const key = idx || type ? `${type ?? "block"}-${idx ? idx.toString() : "0"}` : ""

  switch (type) {
    case BlockType.PAGECONTAINER:
    case BlockType.FRAME:
    case BlockType.PAGE:
      return (
        <Container
          maxWidth={false}
          sx={{
            maxWidth: "1920px",
          }}
          key={key}
        >
          <Stack direction={"column"} className={"section-stack"}>
            {element}
          </Stack>
        </Container>
      )
    case BlockType.NAVIGATION:
      return (
        <Container
          maxWidth={false}
          sx={{
            marginTop: "6rem",
            marginBottom: "4rem",
            display: "flex",
            maxWidth: "1920px",
            height: "100%",
            justifyContent: "flex-end",
            alignItems: "center",
            overflowX: "hidden",
            maxHeight: "calc(100% - 6rem)",
          }}
          key={key}
        >
          {element}
        </Container>
      )

    default:
      return element;
  }
}

/**
 * Get an route-template by section.id
 * @param section
 * @param parentUrl
 * @param inline
 */
export function getSectionElement (section:IKirbySection | IDMSSection, parentUrl:string, inline:boolean) {
  switch (section.id) {
    case 'case':
      return (
        <CasePage
          key={section.url}
          data={section as SectionCase}
          inline={false}
          parentUrl={parentUrl}
      />
    )
    case 'cases':
      return (
        <CasesPage
          key={section.url}
          data={section as SectionCases}
          inline={false}
          parentUrl={parentUrl}
        />
      )
    default:
      return (
        <DefaultPage
          key={section.url}
          data={section as SectionDefault}
          inline={false}
          parentUrl={parentUrl}
        />
    )
  }
}


export function getBlockElement (b:Block | IDMBlock, idx:number, scope?:string):React.JSX.Element | null {
  if (b) {
    switch (b.type) {
      case BlockType.HEADLINE:
        return <IDMHeadline key={`heading-${idx.toString()}`} data={b} scope={scope} />
      case BlockType.TEXT:
        return <IDMText key={`text-${idx.toString()}`} data={b} scope={scope} />
      case BlockType.LINK:
        return <IDMLink key={`link-${idx.toString()}`} data={b} scope={scope} />
      case BlockType.SLIDESHOW2:
        return (
          <KirbySlideshow
            data={b} key={`slideshow-${idx.toString()}`}
            carouselProps={{
              buttonSx: slideshowButtonSx,
              indicatorPos: "right",
              navigationPos: "spread",
            }}
          >
            {b.slides && b.slides.map((slide, idx) => {
              return getBlockElement(slide, idx);
            })}
          </KirbySlideshow>
        );
      case BlockType.SLIDE:
        return (
          <KirbySlide key={`slide-${idx.toString()}`} data={b}>
            {b.blocks && b.blocks.map((block, idx) => {
              return getBlockElement(block, idx, "slide");
            })}
          </KirbySlide>
        );
      case BlockType.BLOCKGROUP:
        const elements = b.items?.map((bc:Block) => {
          return getBlockElement(bc, 0, scope)
        });
        return <KirbyBlockGroup key={`group-${idx.toString()}`} data={b} idx={idx+1} elements={elements}/>
      case BlockType.PAGECONTAINER:
        return <PageContainer key={`page-${idx.toString()}`} data={b} getBlocks={getBlockElement} />
      case IDMBlockType.DIVIDER:
        return <IDMDivider key={`divider-${idx.toString()}`} data={b} scope={scope}/>
      case IDMBlockType.COOKIERESET:
        return <CookieReset key={`cookiereset-${idx.toString()}`} data={b} scope={scope} />
      case IDMBlockType.CASESOVERVIEW:
        return <CasesOverview key={`cases-${idx.toString()}`}  data={b} />
      case IDMBlockType.FORM:
        return <KirbyForm key='anchor-form-${idx.toString()' data={b} />
      default:
        return getKirbyBlockElement(b, idx, scope);
    }
  }
  return null;
}

export function wrapWithRow (element:ReactNode, type?:BlockType | "", idx?:number):React.JSX.Element {
  const key = idx || type ? `${type ?? "block"}-${idx ? idx.toString() : "0"}` : ""

  switch (type) {
    case BlockType.ANIMATIONS:
    case BlockType.ANIMATION:
    case BlockType.SLIDER:
    case BlockType.PAGECONTAINER:
    case BlockType.FRAME:
      return (
        <Container maxWidth={"xl"} key={key}>
      <Grid container>
      <Grid item xs={12}>
      {element}
      </Grid>
      </Grid>
      </Container>
    )
    default:
      return (
        <Container maxWidth={"xl"} key={key}>
      <Grid container rowSpacing={4} justifyContent={"center"}>
      <Grid item xs={0} lg={2} xl={2} />
    <Grid item xs={12} lg={8} xl={8}>
      {element}
      </Grid>
      <Grid item xs={0} lg={2} xl={2} />
    </Grid>
    </Container>
    )
  }
}