import React, { useEffect } from "react";
import {
  AppStatus,
  KirbyRouter,
  ScrollToTop,
  useAppState,
  useKirbyData,
} from "@gk-lab/kirbyreact";
import { Box, Container, Fab, Typography } from "@mui/material";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { getSectionElement } from "./utils/sectionUtils";
import IDMAppHeader from "./components/IDMAppHeader";
import IDMNavigation from "./components/IDMNavigation";
import { useLocation, useNavigationType } from "react-router-dom";
import { accent, defaultPadding, primary, white } from "./Theme";
import StickyImage from "./components/StickyImage";
import { getPath } from "./config";

function App() {
  const { listedSections, state, sticky, hidemainnavigation } = useAppState();
  // eslint-disable-next-line
  const kirbyData = useKirbyData(getPath());
  // const [showDisclaimer, setShowDisclaimer] = useState(false);

  const [open, setOpen] = React.useState(false);

  const { pathname, hash } = useLocation();
  const navigationType = useNavigationType();

  // TODO Scroll überprüfen
  useEffect(() => {
    const getMeTo: HTMLElement | null = hash
      ? document.getElementById(hash.replace("#", "").toLowerCase())
      : null;
    if (getMeTo) {
      setTimeout(() => {
        getMeTo.scrollIntoView({ behavior: "smooth" });
      }, 500);
    } else if (navigationType !== "POP") {
      window.scrollTo(0, 0);
    }
  }, [navigationType, pathname, hash]);

  return (
    <Box>
      {state === AppStatus.LOADED && listedSections && (
        <>
          {hidemainnavigation === "false" && (
            <IDMNavigation
              open={open}
              handleClose={() => setOpen(!open)}
              handleClick={() => setOpen(false)}
              mainItems={kirbyData.data?.navitems}
              metaItems={kirbyData.data?.metaitems}
              socials={kirbyData.data?.sociallinks}
            />
          )}
          <Box component={"main"} sx={{ paddingTop: "90px" }}>
            <Container maxWidth={false}>
              <div id={"back-to-top-anchor"}></div>
              <KirbyRouter
                sections={listedSections}
                getSectionElement={getSectionElement}
              />
            </Container>
          </Box>
          <IDMAppHeader
            sx={{
              gridArea: "header",
              maxHeight: "90px",
              zIndex: 3000,
            }}
            maxWidth={false}
            containerSx={{
              maxWidth: "1920px",
            }}
            asOverlay={open}
            open={open}
            handleOpen={() => setOpen(true)}
            handleClose={() => setOpen(false)}
          >
            <Container
              maxWidth={false}
              sx={{
                maxWidth: "1920px",
                position: "fixed",
                bottom: 0,
              }}
            >
              {sticky && !open && (
                <StickyImage
                  data={sticky}
                  sx={{
                    right: { xs: "1rem", md: "4.5rem" },
                    bottom: "10px",
                    // mr: "-1rem",
                  }}
                />
              )}
              {!open && (
                <ScrollToTop
                  boxSx={{
                    position: "absolute",
                    bottom: "1rem",
                    left: { xs: "1rem", md: "4.5rem" },
                    right: "unset",
                  }}
                >
                  <Fab
                    color="secondary"
                    size="small"
                    aria-label="scroll back to top"
                    sx={{
                      backgroundColor: accent,
                      borderRadius: 0,
                      "&:hover, &:focus": {
                        backgroundColor: primary,
                        color: white,
                      },
                    }}
                  >
                    <KeyboardArrowUpIcon />
                  </Fab>
                </ScrollToTop>
              )}
            </Container>
          </IDMAppHeader>
          {/*<KirbySnackbar
                data={{type: BlockType.SNACKBAR, text: "Disclaimer"}}
                handleClose={() => setShowDisclaimer(false)}
                open={showDisclaimer}
                muiProps={{
                  anchorOrigin: { vertical: "bottom", horizontal: "left" }
                }}
              />*/}
        </>
      )}
      {state !== AppStatus.LOADED && (
        <Box p={4}>
          <Typography component={"p"} fontSize={"1rem"}>
            Einen Moment bitte...
          </Typography>
        </Box>
      )}
    </Box>
  );
}

export default App;
