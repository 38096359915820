import {
  Block,
  BlockCard,
  BlockHeadline,
  BlockType,
  FlexAlign,
  IKirbyBlock,
  IKirbyImage,
  ResponsiveSx,
} from "@gk-lab/kirbyreact";

export enum IDMBlockType {
  DIVIDER = "divider",
  FORM = "form",
  STICKY = "sticky",
  COOKIERESET = "cookiereset",
  CASESOVERVIEW = "casesoverview",
  CASE = "case",
}

export type SocialLink = {
  name: string;
  url: string;
  icon: string;
};

export interface BlockIdmHeadline extends BlockHeadline {
  divider?: boolean;
  arrow?: boolean;
}

export interface BlockCookieReset extends IKirbyBlock {
  type: IDMBlockType.COOKIERESET;
  text: string;
  variant?: string;
  align?: FlexAlign;
}

export interface BlockDivider extends IKirbyBlock {
  type: IDMBlockType.DIVIDER;
  scope?: string;
}

export interface BlockForm extends IKirbyBlock {
  type: IDMBlockType.FORM;
  cta: string;
  action: string;
  disclaimerCopy: string;
  successCopy?: string;
  errorCopy?: string;
  infos: Block[];
}

export interface BlockSticky extends IKirbyBlock {
  type: IDMBlockType.STICKY;
  src?: string;
  alt?: string;
  to?: string;
  padding?: {
    [key: string]: ResponsiveSx;
  };
}

export interface BlockIDMFooter extends IKirbyBlock {
  type: BlockType.FOOTER;
  top?: Block[];
  background?: string;
  leftheading?: string;
  left?: Block[];
  centerheading?: string;
  center?: Block[];
  rightheading?: string;
  right?: Block[];
  maxwidth?: "sm" | "md" | "lg" | "xl" | "false";
}

export interface IDMBlockCard extends BlockCard {
  tags: string;
}

export interface BlockForm extends IKirbyBlock {
  type: IDMBlockType.FORM;
  cta: string;
  action: string;
  disclaimerCopy: string;
  successCopy?: string;
  errorCopy?: string;
  infos: Block[];
}

export interface BlockCards extends IKirbyBlock {
  type: BlockType.CARDS;
  layout: string;
  style?: string;
  filter?: string;
  cards?: IDMBlockCard[];
}

export interface BlockCasesOverview extends IKirbyBlock {
  type: IDMBlockType.CASESOVERVIEW;
  variant: string;
  tags?: string;
  filter?: boolean;
  cases?: BlockCase[];
}

export interface BlockCase extends IKirbyBlock {
  type: IDMBlockType.CASE;
  title: string;
  subtitle?: any;
  url: string;
  tags?: string;
  image?: IKirbyImage;
  header?: IKirbyImage;
}

export type IDMBlock =
  | BlockForm
  | BlockDivider
  | BlockIdmHeadline
  | BlockCookieReset
  | IDMBlockCard
  | BlockCasesOverview;
